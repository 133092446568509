import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import { ThemeContext } from 'styled-components';
import styled, { css } from 'styled-components';

const H1 = styled.h1`
	font-weight: bold;
	${fontSize}
	${fontFamily}
	${textAlign}
	${lineHeight}
	${fontWeight}
	${letterSpacing}
	${color}
	${space}
	${fontStyle}
	${flexWrap}
	${width}
	${(props) => {
		const theme = useContext(ThemeContext);
		return css`
			color: ${theme.base.primary};
		`;
	}}
`;

const Title = (props) => {
	const theme = useContext(ThemeContext);
	return <H1 {...theme.title} {...props}></H1>;
};

const H2 = styled.h2`
	font-weight: bold;
	${fontSize}
	${fontFamily}
	${textAlign}
	${lineHeight}
	${fontWeight}
	${letterSpacing}
	${color}
	${space}
	${fontStyle}
	${flexWrap}
	${width}
	${(props) => {
		const theme = useContext(ThemeContext);
		return (
			props.primary &&
			css`
				color: ${theme.base.primary};
			`
		);
	}}
`;

export const SubTitle = (props) => {
	const theme = useContext(ThemeContext);
	return <H2 {...theme.subtitle} {...props}></H2>;
};

export default Title;
