import React, { useCallback } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { formErrorHandler } from '@common/utils/form';
import { useIntl } from 'react-intl';
const FormItem = Form.Item;

const LoginForm = ({ form, onSubmit }) => {
	const intl = useIntl();
	const handleFinish = useCallback((values) => {
		onSubmit(values)
			.then()
			.catch((err) => {
				formErrorHandler(err, values, form);
			});
	}, []);

	return (
		<Form onFinish={handleFinish} className="login-form" layout="vertical">
			<FormItem
				label={intl.formatMessage({
					id: 'common.email',
					defaultMessage: `Email`,
				})}
				name="email"
				rules={[
					{
						required: true,
						message: 'Please input your email address',
					},
					{
						type: 'email',
						message: 'Please enter a valid email address',
					},
				]}>
				<Input placeholder="Email" />
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.password',
					defaultMessage: `Password`,
				})}
				className="mb-3"
				name="password"
				rules={[
					{
						required: true,
						message: 'Please input your password',
					},
				]}>
				<Input type="password" placeholder="Password" />
			</FormItem>
			<FormItem className="mb-5" name="remember">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.rememberMe',
						defaultMessage: `Remember me`,
					})}
				</Checkbox>
			</FormItem>
			<FormItem>
				<Button
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.login',
						defaultMessage: `Login`,
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

export default LoginForm;
