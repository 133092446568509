import { SET_AUTH, REMOVE_AUTH } from './actionTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_AUTH:
			return action.payload;
		case REMOVE_AUTH:
			return {};
		default:
			return state;
	}
};
