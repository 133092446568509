import { SET_SITE } from './actionTypes';

const setSite = (data) => (dispatch) => {
	if (data) {
		dispatch({ type: SET_SITE, payload: data });
	}

	return Promise.resolve(data);
};

export { setSite };
