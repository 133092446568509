import React, { useCallback } from 'react';
import { Avatar, Menu } from 'antd';
import { UserOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { getCookie } from '@common/utils/cookie';
const SubMenu = Menu.SubMenu;

const RightMenu = (props) => {
	const { auth, location } = props;
	const authCookie = getCookie('auth');

	const initials = useCallback(() => {
		let init = 'User';
		if (!isEmpty(auth)) {
			const { firstName, lastName } = auth;
			if (firstName || lastName) {
				init = '';
			}

			if (firstName) {
				init += firstName.substring(0, 1);
			}

			if (lastName) {
				init += lastName.substring(0, 1);
			}

			return init;
		}

		return init;
	}, [auth]);

	const handleClick = (e) => {};

	const menus = useCallback(
		(menuProps) =>
			authCookie ? (
				window.matchMedia('(min-width: 893px)').matches ? (
					<Menu {...menuProps}>
						<SubMenu
							key="account"
							title={
								<Avatar
									style={{
										backgroundColor: '#333',
										verticalAlign: 'middle',
										color: '#FFF',
									}}
									size="small">
									{initials()}
								</Avatar>
							}>
							<Menu.Item key="/logout">
								<Link to="/logout" className="link">
									Logout
								</Link>
							</Menu.Item>
						</SubMenu>
					</Menu>
				) : (
					<Menu {...menuProps}>
						<Menu.Item key="/logout">
							<Link to="/logout" className="link">
								Logout
							</Link>
						</Menu.Item>
					</Menu>
				)
			) : (
				<Menu {...menuProps}>
					<Menu.Item key="/register" icon={<UserAddOutlined />}>
						<Link to="/register" className="link">
							Register
						</Link>
					</Menu.Item>
					<Menu.Item key="/login" icon={<UserOutlined />}>
						<Link to="/login" className="link">
							Login
						</Link>
					</Menu.Item>
				</Menu>
			),
		[auth]
	);

	if (window.matchMedia('(min-width: 893px)').matches) {
		return menus({ mode: 'horizontal', selectedKeys: [location.pathname] });
	} else {
		return menus({
			mode: 'inline',
			selectedKeys: [location.pathname],
			defaultOpenKeys: ['setting'],
			onClick: handleClick,
		});
	}
};

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	};
};

export default connect(mapStateToProps, {})(RightMenu);
