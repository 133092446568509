import { popIn } from './services';

export const formInitialState = (fields) => {
	const field = {};
	for (let i = 0; i < fields.length; i++) {
		const key = Object.keys(fields[i])[0];
		// let fieldKey = null;
		// if (fields[i][key].options && fields[i][key].value) {
		// 	fields[i][key].options.map(option => {
		// 		if (option.value === fields[i][key].value) {
		// 			fieldKey = option.key;
		// 		}
		// 	});
		// }

		field[key] = {
			disabled: false,
			touched: false,
			...fields[i][key],
			error: null,
		};
	}

	return field;
};

export const formResetError = (fields) => {
	const field = {};
	for (let i = 0; i < fields.length; i++) {
		field[Object.keys(fields[i])[0]] = {
			...field[fields[i]],
			error: null,
		};
	}

	return field;
};

export const formValidation = (fields) => {
	const data = {};
	let err = false;
	for (let i = 0; i < Object.keys(fields).length; i++) {
		const key = Object.keys(fields)[i];
		const error = validationRules(
			fields[key].validation,
			fields[key].value
		);

		data[key] = {
			...fields[key],
			touched: true,
			error,
		};
		if (error) {
			err = true;
		}
	}

	return { data, err };
};

export const formValidate = (scope) => {
	const fields = scope.state.fields;
	const validation = formValidation(fields);

	scope.setState({ fields: validation.data });

	return validation.err;
};

const validationRules = (validation, value) => {
	for (let v = 0; v < validation.length; v++) {
		const val = validation[v];
		switch (val.rule) {
			case 'required':
				if (!value || value.length === 0) {
					return val.message ? val.message : 'This field is required';
				}
				break;
			case 'email':
				const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!emailRegex.test(value)) {
					return val.message ? val.message : 'The email is not valid';
				}
				break;
			case 'custom':
				if (!val.validate(value)) {
					return val.message;
				}
				break;
			default:
				break;
		}
	}

	return null;
};

export const formValidationRules = validationRules;

const formChange = (scope, type, field, value, key, file, callback) => {
	const f = {
		fields: scope.state.fields,
	};
	// const keys = Object.keys(scope.state.fields);
	// for (let t = 0; t < keys.length; t++) {
	// 	const k = keys[t];
	// if (type === k) {
	if (
		typeof value === 'string' ||
		typeof value === 'boolean' ||
		value instanceof Array
	) {
		value = { value };
	}

	let fieldValues = {
		...field,
		touched: true,
		error: null,
		...value,
		...key,
	};

	if (file) {
		fieldValues['file'] = file;
	}

	f['fields'][type] = fieldValues;

	// 	} else {
	// 		f['fields'][k] = {
	// 			...scope.state.fields[k]
	// 		};
	// 	}
	// }

	scope.setState(f, () => {
		if (callback) {
			callback(scope.state['fields'][type].value);
		}
	});
};

export const formChangeText = (scope, type, field, value, key, callback) => {
	formChange(scope, type, field, value, key ? key : value, null, callback);
};

export const formChangeFile = (scope, type, field, value, file, callback) => {
	formChange(scope, type, field, value, value, file, callback);
};

export const formValidPassword = (pwd) => {
	let pwdTest = {
		strength: 'Weak',
		text: undefined,
	};

	// match rule
	const matches = {
		// > 8 char, including both letter & digit
		Okay: /^(?=.*[a-zA-Z])(?=.*\d)([a-zA-Z\d$@$!%*#?&]){8,}$/,
		// > 8 char, including uppercase letter & lowercase letter & digit, no indentical digit 3+
		Medium: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*([a-zA-Z\d$@$!%*#?&])\1{2})([a-zA-Z\d$@$!%*#?&]){8,}$/,
		// > 12 char, including uppercase letter & lowercase letter & digit,
		// no indentical digit 3+, no sequential digit/letter 3+
		Strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*([a-zA-Z\d$@$!%*#?&])\1{2})([a-zA-Z\d$@$!%*#?&]){12,}$/,
	};

	// If the input contains sequential numbers/letters
	function testSequential(s) {
		// Check for sequential numbers
		for (const i in s) {
			if (+s[+i + 1] === +s[i] + 1 && +s[+i + 2] === +s[i] + 2)
				return false;
		}
		// Check for sequential letters
		for (const i in s) {
			if (
				String.fromCharCode(s.charCodeAt(i) + 1) === s[+i + 1] &&
				String.fromCharCode(s.charCodeAt(i) + 2) === s[+i + 2]
			)
				return false;
		}
		return true;
	}

	for (const matchKey in matches) {
		if (matches[matchKey].test(pwd)) {
			if (
				matchKey !== 'Strong' ||
				(matchKey === 'Strong' && testSequential(pwd))
			) {
				pwdTest = {
					strength: matchKey,
					text: undefined,
				};
			}
		}
	}

	if (pwdTest.strength === 'Weak') {
		// display help text for invalid password input
		if (!pwd) {
			pwdTest.text = 'Required.';
		} else if (pwd.length < 8) {
			pwdTest.text = 'Your password must be at least 8 characters.';
		} else if (!/^(?=.*[a-zA-Z])(?=.*\d)$/.test(pwd)) {
			pwdTest.text = 'Please include at least a digit and a character.';
		} else {
			pwdTest.text = 'Please check your password input again.';
		}
	}

	return pwdTest;
};

export const formErrorHandler = (err, values, form) => {
	const error = err && err.response && err.response.data;

	console.log(error);

	if (error) {
		if (popIn && error.message) {
			popIn('error', error.message, error.duration);
		}

		const errors = {};
		if (error.errors) {
			if (values && form) {
				const err = error.errors.map((field) => {
					return {
						value: values[field.field],
						name: field.field,
						errors: [new Error(field.error)].map(
							(erro) => erro.message
						),
					};
				});

				form.setFields(err);
			}
		}
		return errors;
	}
};
