import { API_ROOT } from '@config/api';
import request from '@common/utils/request';

const createUser = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

export { createUser };
