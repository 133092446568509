import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Section, View } from '@common/components';
import { Icon, Button, Layout, Spin, Result } from 'antd';
import { isEmpty } from 'lodash';
import { doVerify } from '@resources/Verify/actions';
import { formErrorHandler } from '@common/utils/form';
import qs from 'qs';

const { Content } = Layout;

const Verify = ({ auth, location, history, match, doVerify }) => {
	const { nonce } = match?.params || {};
	const query = location.search.slice(1);
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState({});

	useEffect(() => {
		const email = qs.parse(query).e;
		const referrer = qs.parse(query).r;

		const verify = async () => {
			try {
				await doVerify({ nonce, email, referrer });
				setStatus({
					status: 'success',
					title: 'Email verification successful',
					subTitle: `Your email ${email} has been verified. You are now able to login to Foreward Network`,
				});
				setLoading(false);
			} catch (err) {
				setStatus({
					status: 'error',
					title: 'Email verification failed',
					subTitle: `Your email ${email} has been verified. You are now able to login to Foreward Network`,
				});
				formErrorHandler(err);
			}
		};

		verify();
	}, []);

	return !isEmpty(auth) ? (
		<Redirect to="/dashboard" />
	) : (
		<Content className="view">
			<View>
				<Container centralised>
					{loading ? (
						<Section className="my-5 container-body container-body-md">
							<h1 className="section-title">
								Verifying{' '}
								<Spin
									indicator={
										<Icon
											type="loading"
											style={{
												fontSize: 24,
												color: '#a90af2',
											}}
											spin
										/>
									}></Spin>
							</h1>
						</Section>
					) : (
						<Result
							status={status.status}
							title={status.title}
							subTitle={status.subTitle}
							extra={[
								<Button
									type="primary"
									key="login"
									onClick={() => {
										history.push('/login');
									}}>
									Login
								</Button>,
							]}
						/>
					)}
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	};
};

export default connect(mapStateToProps, { doVerify })(Verify);
