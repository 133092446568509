import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import styled, { css } from 'styled-components';
import { ThemeContext } from 'styled-components';
import './assets/section.scss';

const Wrapper = styled.section`
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		position: relative;
		${(props) => {
			const theme = useContext(ThemeContext);
			return (
				props.shadowed &&
				css`
					box-shadow: ${theme.base.shadow} 0px 4px 8px 0px,
						transparent 0px 0px 0px;
				`
			);
		}}
		${(props) => {
			const theme = useContext(ThemeContext);
			console.log(props);
			return (
				props.hoverable &&
				css`
					&:hover {
						box-shadow: ${theme.base.shadow} 0px 16px 32px 0px,
							transparent 0px 0px 0px;
					}
				`
			);
		}}
`;

const Section = (props) => {
	const {
		wrapperStyle,
		children,
		className,
		hoverable,
		shadowed,
		bordered,
		...restProps
	} = props;
	const theme = useContext(ThemeContext);
	return (
		<Wrapper
			className={`fn-section ${shadowed ? 'shadowed' : ''} ${
				hoverable ? 'hoverable' : ''
			} ${bordered ? 'bordered' : ''} ${className ? className : ''}`}
			{...theme.card}
			{...wrapperStyle}
			{...restProps}
			hoverable={hoverable}
			shadowed={shadowed}>
			{children}
		</Wrapper>
	);
};

export default Section;
