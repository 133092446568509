import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '@common/timelines';

import Dashboard from '@containers/Dashboard';
import ForgotPassword from '@containers/ForgotPassword';
import Login from '@containers/Login';
import Logout from '@containers/Logout';
import Register from '@containers/Register';
import ResetPassword from '@containers/ResetPassword';
import Verify from '@containers/Verify';
import {
	Analytics,
	AuthorizedRoute,
	Middleware,
	Notifications,
	Navbar,
} from '@common/fragments';
import Pusher from 'pusher-js';
import {
	FOREWARD_PUSHER_KEY,
	FOREWARD_PUSHER_CLUSTER,
} from '@common/config/app';

import { LeftMenu, RightMenu } from '@fragments';

import ScrollToTop from '@common/fragments/ScrollToTop';
import { setPusherClient } from 'react-pusher';

const pusherClient = new Pusher(FOREWARD_PUSHER_KEY, {
	cluster: FOREWARD_PUSHER_CLUSTER,
	encrypted: true,
});

setPusherClient(pusherClient);

class Base extends Component {
	render() {
		let basename = '';

		console.log(this.props);

		return (
			<BrowserRouter basename={basename}>
				<ScrollToTop>
					<Route
						path="/"
						render={(props) => {
							let authorise = true;
							let resource = true;

							return (
								<div>
									<Middleware
										{...props}
										resource={resource}
										authorise={authorise}>
										<Route
											render={(props) => {
												const { location } = props;
												const {
													pathname,
													key,
												} = location;

												return (
													<>
														<Navbar
															leftMenu={
																<LeftMenu
																	{...props}
																/>
															}
															rightMenu={
																<RightMenu
																	{...props}
																/>
															}
														/>
														<TransitionGroup
															component={null}>
															<Transition
																key={key}
																appear={true}
																onEnter={(
																	node,
																	appears
																) => {
																	if (node) {
																		return play(
																			pathname,
																			node,
																			appears
																		);
																	}
																}}
																onExit={(
																	node,
																	appears
																) => {
																	if (node) {
																		return exit(
																			node,
																			appears
																		);
																	}
																}}
																timeout={{
																	enter: 750,
																	exit: 150,
																}}>
																<Switch
																	location={
																		location
																	}>
																	<AuthorizedRoute
																		exact
																		path="/"
																		component={
																			Dashboard
																		}
																		{...this
																			.props}
																	/>

																	<AuthorizedRoute
																		exact
																		path="/dashboard"
																		component={
																			Dashboard
																		}
																		{...this
																			.props}
																	/>

																	<Route
																		exact
																		path="/forgot-password"
																		component={
																			ForgotPassword
																		}
																		{...this
																			.props}
																	/>
																	<Route
																		exact
																		path="/reset-password/:nonce"
																		component={
																			ResetPassword
																		}
																		{...this
																			.props}
																	/>
																	<Route
																		exact
																		path="/login"
																		component={
																			Login
																		}
																		{...this
																			.props}
																	/>
																	<Route
																		exact
																		path="/logout"
																		component={
																			Logout
																		}
																		{...this
																			.props}
																	/>
																	<Route
																		exact
																		path="/register"
																		component={
																			Register
																		}
																		{...this
																			.props}
																	/>
																	<Route
																		exact
																		path="/verify/:nonce"
																		component={
																			Verify
																		}
																		{...this
																			.props}
																	/>
																</Switch>
															</Transition>
														</TransitionGroup>
													</>
												);
											}}
										/>
									</Middleware>
								</div>
							);
						}}
					/>
					<Route path="/" component={Analytics} {...this.props} />
					<Route path="/" component={Notifications} {...this.props} />
				</ScrollToTop>
			</BrowserRouter>
		);
	}
}

export default Base;
