import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import RegisterForm from './Form';
import { Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { createUser } from '@resources/User/actions';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import './assets/register.scss';

const { Content } = Layout;

const Register = (props) => {
	const { location, createUser } = props;

	const queryStrings = qs.parse(location.search.slice(1));

	const handleSubmit = (data) => {
		const payload = {
			...data,
			refs: queryStrings,
		};

		return createUser(payload)
			.then((response) => Promise.resolve(response))
			.catch((err) => Promise.reject(err));
	};

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Users | Register</title>
			</Helmet>
			<Container centralised>
				<View>
					<Section className="my-5 container-body container-body-md">
						<Title size="lg">Registration</Title>

						<RegisterForm {...props} onSubmit={handleSubmit} />
						<Row
							type="flex"
							justify="center"
							className="login-links">
							<Col>
								Already have an account?{' '}
								<Link to="/login">Login</Link>
							</Col>
						</Row>
					</Section>
				</View>
			</Container>
		</Content>
	);
};

export default connect(null, { createUser })(Register);
