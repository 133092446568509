import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Container, Section, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { doLogout } from '@resources/Authentication/actions';
import { deleteCookie } from '@common/utils/cookie';
import qs from 'qs';

const { Content } = Layout;

const Logout = ({ doLogout, history, location }) => {
	const queryStrings = qs.parse(location.search.slice(1));

	useEffect(() => {
		doLogout()
			.then(() => {
				deleteCookie('auth');
				if (queryStrings.s) {
					window.location.href = `${queryStrings.s}`;
				} else {
					history.push('/login');
				}
			})
			.catch((e) => {});
	}, []);

	return (
		<Content>
			<Container>
				<View>
					<Row>
						<Col md={{ offset: 8, span: 8 }}>
							<Section>LOGGING OUT...</Section>
						</Col>
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps, { doLogout })(Logout);
