import React, { Component } from 'react';
import { notification } from 'antd';
import Pusher from 'react-pusher';

class Notifications extends Component {
	componentDidMount() {}

	componentWillMount() {}

	handleNotification(noti) {
		const { type, title, message } = noti;
		if (type && title && message) {
			notification[type]({ message: title, description: message });
		}
	}

	render() {
		return (
			<Pusher
				channel="private"
				event="notification"
				onUpdate={this.handleNotification.bind(this)}
			/>
		);
	}
}

export default Notifications;
