import { API_ROOT } from '@config/api';
import {
	SET_ORGANIZATIONS,
	SET_ORGANIZATION,
	ADD_ORGANIZATION,
	UPDATE_ORGANIZATION,
	CLEAR_ORGANIZATION,
} from './actionTypes';
import request from '@common/utils/request';

const fetchOrganizations = (type) => (dispatch) =>
	request('get', `${API_ROOT}/organizations`)
		.then((response) => {
			dispatch({
				type: SET_ORGANIZATIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchOrganizationsPublic = (type) => (dispatch) =>
	request('get', `${API_ROOT}/organizations/public`)
		.then((response) => {
			dispatch({
				type: SET_ORGANIZATIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchOrganization = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/organizations/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ORGANIZATION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createOrganization = (data) => (dispatch) =>
	request('post', `${API_ROOT}/organizations`, data)
		.then((response) => {
			dispatch({
				type: ADD_ORGANIZATION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateOrganization = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/organizations/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ORGANIZATION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearOrganization = () => (dispatch) =>
	dispatch({
		type: CLEAR_ORGANIZATION,
	});

export {
	fetchOrganizations,
	fetchOrganizationsPublic,
	fetchOrganization,
	createOrganization,
	updateOrganization,
	clearOrganization,
};
