let env, pusher_id, pusher_key, pusher_cluster;

const hostname = window && window.location && window.location.hostname;
const protocol = window.location.protocol;
let subdomain = hostname.split('.')[0];

if (subdomain === `staging`) {
	pusher_id = '810978';
	pusher_key = '1d6e41a82ac1606f6779';
	pusher_cluster = 'ap1';
} else if (subdomain === `uat`) {
	pusher_id = '810978';
	pusher_key = '1d6e41a82ac1606f6779';
	pusher_cluster = 'ap1';
} else if (subdomain === `www` || subdomain === process.env.REACT_APP_PRODUCT) {
	pusher_id = '810979';
	pusher_key = 'c1de835885a0939d9272';
	pusher_cluster = 'ap1';
} else if (/^qa/.test(hostname)) {
	pusher_id = '810977';
	pusher_key = '42689551388674d18f10';
	pusher_cluster = 'ap1';
} else {
	pusher_id = '810977';
	pusher_key = '42689551388674d18f10';
	pusher_cluster = 'ap1';
}

export const APP_ROOT = `${protocol}//${hostname}`;

export const API_ENV = env;

export const APP_PUSHER_ID = pusher_id;
export const APP_PUSHER_KEY = pusher_key;
export const APP_PUSHER_CLUSTER = pusher_cluster;
