import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCookie } from '@common/utils/cookie';
const SubMenu = Menu.SubMenu;

const LeftMenu = ({ auth }) => {
	const intl = useIntl();
	const authCookie = getCookie('auth');
	const menus = useCallback(
		(menuProps) =>
			authCookie ? (
				<Menu {...menuProps}>
					<Menu.Item key="app">
						<Link to="/apps" className="link">
							{intl.formatMessage({
								id: 'common.app',
								defaultMessage: 'App',
							})}
						</Link>
					</Menu.Item>
					<Menu.Item key="module">
						<Link to="/modules" className="link">
							{intl.formatMessage({
								id: 'common.module',
								defaultMessage: 'Module',
							})}
						</Link>
					</Menu.Item>
					<Menu.Item key="user">
						<Link to="/users" className="link">
							{intl.formatMessage({
								id: 'common.user',
								defaultMessage: 'User',
							})}
						</Link>
					</Menu.Item>
					<Menu.Item key="product">
						<Link to="/products" className="link">
							{intl.formatMessage({
								id: 'common.product',
								defaultMessage: 'Product',
							})}
						</Link>
					</Menu.Item>
					<Menu.Item key="page">
						<Link to="/pages" className="link">
							{intl.formatMessage({
								id: 'common.page',
								defaultMessage: 'Page',
							})}
						</Link>
					</Menu.Item>

					<Menu.Item key="organization">
						<Link to="/organizations" className="link">
							{intl.formatMessage({
								id: 'common.organization',
								defaultMessage: 'Organization',
							})}
						</Link>
					</Menu.Item>

					<Menu.Item key="group">
						<Link to="/groups" className="link">
							{intl.formatMessage({
								id: 'common.group',
								defaultMessage: 'Group',
							})}
						</Link>
					</Menu.Item>

					<Menu.Item key="event">
						<Link to="/events" className="link">
							{intl.formatMessage({
								id: 'common.event',
								defaultMessage: 'Event',
							})}
						</Link>
					</Menu.Item>

					<SubMenu
						key="setting"
						title={
							<span>
								{intl.formatMessage({
									id: 'common.settings',
									defaultMessage: 'Settings',
								})}
							</span>
						}>
						<Menu.Item key="setting:modules">
							<Link to="/modules" className="link">
								{intl.formatMessage({
									id: 'common.module',
									defaultMessage: 'Module',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:submodules">
							<Link to="/submodules" className="link">
								{intl.formatMessage({
									id: 'common.submodule',
									defaultMessage: 'Submodule',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:roles">
							<Link to="/roles" className="link">
								{intl.formatMessage({
									id: 'common.role',
									defaultMessage: 'Role',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:access">
							<Link to="/accesses" className="link">
								{intl.formatMessage({
									id: 'common.access',
									defaultMessage: 'Access',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:currency">
							<Link to="/currencies" className="link">
								{intl.formatMessage({
									id: 'common.currency',
									defaultMessage: 'Currency',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:contentTypes">
							<Link to="/contentTypes" className="link">
								{intl.formatMessage({
									id: 'common.contentType',
									defaultMessage: 'Content Type',
								})}
							</Link>
						</Menu.Item>
						<Menu.Item key="setting:relationships">
							<Link to="/relationships" className="link">
								{intl.formatMessage({
									id: 'common.relationship',
									defaultMessage: 'Relationship',
								})}
							</Link>
						</Menu.Item>
					</SubMenu>
				</Menu>
			) : null,
		[auth]
	);

	if (window.matchMedia('(min-width: 893px)').matches) {
		return menus({ mode: 'horizontal' });
	} else {
		return menus({ mode: 'inline', defaultOpenKeys: ['setting'] });
	}
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {})(LeftMenu);
