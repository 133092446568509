import React, { useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import { popIn } from '@common/utils/services';
import { formErrorHandler } from '@common/utils/form';
import { useIntl } from 'react-intl';

const FormItem = Form.Item;

const ForgotPasswordForm = ({ history, onSubmit }) => {
	const [form] = Form.useForm();
	const intl = useIntl();
	const handleFinish = useCallback(
		(values) => {
			onSubmit(values)
				.then((response) => {
					popIn(
						'success',
						`A reset password link has been sent to your email`
					);
					history.push('/login');
				})
				.catch((err) => formErrorHandler(err, values, form));
		},
		[onSubmit]
	);

	return (
		<Form onFinish={handleFinish} form={form} className="login-form">
			<FormItem
				label={`Email`}
				name="email"
				rules={[
					{
						required: true,
						message: 'Please input your email address',
					},
					{
						type: 'email',
						message: 'Please enter a valid email address',
					},
				]}>
				<Input placeholder="Email" />
			</FormItem>
			<FormItem>
				<Button
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

export default ForgotPasswordForm;
