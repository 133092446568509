import React from 'react';

import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ForgotPasswordForm from './Form';
import { Container, Section, Title, View } from '@common/components';
import { Col, Row, Layout } from 'antd';
import { isEmpty } from 'lodash';
import { doForgotPassword } from '@resources/ForgotPassword/actions';
import { Helmet } from 'react-helmet';
import './assets/forgot-password.scss';

const { Content } = Layout;

const ForgotPassword = (props) => {
	const { auth, doForgotPassword } = props;

	const handleSubmit = (data) => {
		return doForgotPassword(data)
			.then((response) => {
				return Promise.resolve(response);
			})
			.catch((err) => Promise.reject(err));
	};

	return !isEmpty(auth) ? (
		<Redirect to="/dashboard" />
	) : (
		<Content>
			<Helmet>
				<title>Foreward Network | User | Forgot Password</title>
			</Helmet>
			<Container centralised>
				<View>
					<Section className="my-5 container-body container-body-md">
						<Title size="lg">Forgot Password</Title>
						<ForgotPasswordForm onSubmit={handleSubmit} />
						<Row
							type="flex"
							justify="center"
							className="login-links">
							<Col>
								Remember your login?{' '}
								<Link to="/login">Login</Link>
							</Col>
						</Row>
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps, { doForgotPassword })(ForgotPassword);
