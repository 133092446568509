import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { popIn } from '@common/utils/services';
import { formErrorHandler } from '@common/utils/form';
import qs from 'qs';
import { useIntl } from 'react-intl';
const FormItem = Form.Item;

const RegisterForm = ({ history, location, onSubmit }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const intl = useIntl();
	useEffect(() => {
		if (location.search) {
			const email = qs.parse(location.search.slice(1)).e;
			form.setFieldsValue({ email });
		}
	}, []);

	const handleFinish = useCallback((values) => {
		setLoading(true);
		onSubmit(values)
			.then((response) => {
				setLoading(false);
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.registration',

						defaultMessage: `Sign up successful 🙌! We have sent an email to {email}, please follow the steps to verify your foreward network account`,
						values: { email: response.data.data.email },
					})
				);
				history.push('/login');
			})
			.catch((err) => {
				setLoading(false);
				formErrorHandler(err, values, form);
			});
	}, []);

	return (
		<Form
			layout="vertical"
			name={form}
			onFinish={handleFinish}
			className="login-form">
			<FormItem
				label={intl.formatMessage({
					id: 'common.firstName',
					defaultMessage: 'First name',
				})}
				name="firstName"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.firstName.required',
							defaultMessage: 'Please enter first name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.firstName',
						defaultMessage: 'First name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.lastName',
					defaultMessage: 'Last name',
				})}
				name="lastName"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.lastName.required',
							defaultMessage: 'Please enter last name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.lastName',
						defaultMessage: 'Last name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.email',
					defaultMessage: 'Email',
				})}
				name="email"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.email.required',
							defaultMessage: 'Please enter email address',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.email',
						defaultMessage: 'Email',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.username',
					defaultMessage: 'Username',
				})}
				name="username"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.username.required',
							defaultMessage: 'Please enter username address',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.username',
						defaultMessage: 'Username',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.password',
					defaultMessage: 'Password',
				})}
				name="password"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.password.required',
							defaultMessage: 'Please enter password',
						}),
					},
				]}>
				<Input.Password
					placeholder={intl.formatMessage({
						id: 'common.password',
						defaultMessage: 'Password',
					})}
				/>
			</FormItem>
			<FormItem>
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.signup',
						defaultMessage: 'Sign Up',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

export default RegisterForm;
