import { API_ROOT } from '@config/api';
import { SET_AUTH, REMOVE_AUTH } from './actionTypes';
import request from '@common/utils/request';

const doLogin = (data, type) => (dispatch) =>
	request('post', `${API_ROOT}/login${type ? type : ''}`, data)
		.then((response) => {
			dispatch({ type: SET_AUTH, payload: response.data });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const doLogout = (data) => (dispatch) =>
	request('get', `${API_ROOT}/logout`)
		.then((response) => {
			dispatch({ type: REMOVE_AUTH });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

export { doLogin, doLogout };
