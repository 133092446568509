import { API_ROOT } from '@config/api';
import request from '@common/utils/request';

const doForgotPassword = (data, type) => (dispatch) =>
	request(
		'post',
		`${API_ROOT}/users/forgot-password${type ? type : ''}`,
		data
	)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

export { doForgotPassword };
