import { SET_SITE } from './actionTypes';

export default (state = null, action) => {
	switch (action.type) {
		case SET_SITE:
			return action.payload;
		default:
			return state;
	}
};
