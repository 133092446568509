import React from 'react';
import { Container, Section, Title, View } from '@common/components';
import { Layout } from 'antd';

const { Content } = Layout;

const Dashboard = () => {
	return (
		<Content className="view">
			<Container centralised>
				<View>
					<Section className="my-5 container-body container-body-md">
						<Title>Dashboard</Title>
					</Section>
				</View>
			</Container>
		</Content>
	);
};

export default Dashboard;
