import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoginForm from './Form';
import { Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { doLogin } from '@resources/Authentication/actions';
import { setSite } from '@resources/Site/actions';
import { Helmet } from 'react-helmet';
import { getCookie, setCookie } from '@common/utils/cookie';
import { useIntl } from 'react-intl';
import qs from 'qs';
import './assets/login.scss';

const { Content } = Layout;

const Login = ({ site, history, doLogin, location, social, setSite }) => {
	const auth = getCookie('auth');
	const intl = useIntl();
	const queryStrings = qs.parse(location.search.slice(1));

	useEffect(() => {
		if (auth) {
			window.location.href = `${site || queryStrings.s}`;
		} else {
			setSite(queryStrings.s);
		}
	}, []);

	const handleSubmit = useCallback(
		(data) => {
			return doLogin(data)
				.then((response) => {
					setCookie('auth', response.data.token);
					if (site || queryStrings.s) {
						window.location.href = `${site || queryStrings.s}`;
					} else {
						history.replace('/dashboard');
					}
				})
				.catch((err) => Promise.reject(err));
		},
		[site, setCookie]
	);

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Login</title>
			</Helmet>
			<Container centralised>
				<View>
					<Section
						hoverable
						className="my-5 container-body container-body-md">
						<Title size="lg">Login</Title>
						<LoginForm onSubmit={handleSubmit} />
						{social}
						<Row
							type="flex"
							justify="center"
							className="login-links">
							<Col>
								Don't have an account?{' '}
								<Link to="/register">
									{intl.formatMessage({
										id: 'common.signup',
										defaultMessage: 'Sign Up',
									})}
								</Link>
							</Col>
						</Row>
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ site }) => {
	return { site };
};

export default connect(mapStateToProps, { doLogin, setSite })(Login);
