import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { popIn } from '@common/utils/services';
import { formErrorHandler } from '@common/utils/form';
import { useIntl } from 'react-intl';

const FormItem = Form.Item;

const ResetPasswordForm = ({ history, onSubmit }) => {
	const [form] = Form.useForm;
	const intl = useIntl();
	const [confirmDirty, setConfirmDirty] = useState();
	const handleConfirmBlur = useCallback((e) => {
		const value = e.target.value;
		setConfirmDirty(confirmDirty || !!value);
	}, []);

	const compareToFirstPassword = useCallback((rule, value, callback) => {
		if (value && value !== form.getFieldValue('newPwd')) {
			callback('Confirm your password');
		} else {
			callback();
		}
	}, []);

	const validateToNextPassword = useCallback((rule, value, callback) => {
		if (value && confirmDirty) {
			form.validateFields(['confirmNewPwd'], { force: true });
		}
		callback();
	}, []);

	const handleResetPassword = useCallback((values) => {
		onSubmit(values)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.resetPassword',
						defaultMessage: `Your password has been reset successfully. You can now login using your new password.`,
					})
				);
				history.push('/login');
			})
			.catch((err) => formErrorHandler(err, values, form));
	}, []);

	return (
		<Form onFinish={handleResetPassword} form={form} className="login-form">
			<FormItem
				label={intl.formatMessage({
					id: 'common.newPassword',
					defaultMessage: `New Password`,
				})}
				name="newPwd"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.newPassword.required',
							defaultMessage: 'Please enter your new password',
						}),
					},
					{
						validator: validateToNextPassword,
					},
				]}>
				<Input
					type="password"
					placeholder={intl.formatMessage({
						id: 'common.newPassword',
						defaultMessage: `New Password`,
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.confirmNewPassword',
					defaultMessage: `Confirm New Password`,
				})}
				name="confirmNewPwd"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.confirmNewPassword.required',
							defaultMessage: 'Please confirm your new password',
						}),
					},
					{
						validator: compareToFirstPassword,
					},
				]}>
				<Input
					type="password"
					placeholder={intl.formatMessage({
						id: 'common.confirmNewPassword',
						defaultMessage: `Confirm New Password`,
					})}
					onBlur={handleConfirmBlur}
				/>
			</FormItem>
			<FormItem>
				<Button
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.resetPassword',
						defaultMessage: `Reset Password`,
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

export default connect()(ResetPasswordForm);
