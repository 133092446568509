import React, { useEffect } from 'react';
import { Container, Section, Title, View } from '@common/components';
import { connect } from 'react-redux';
import { Layout, message } from 'antd';
import { Helmet } from 'react-helmet';
import { doResetPassword } from '@resources/ResetPassword/actions';
import ResetPasswordForm from './Form';
import qs from 'qs';

const { Content } = Layout;

const ResetPassword = (props) => {
	const { match, location, doResetPassword } = props;
	const { nonce } = match.params;
	const email = qs.parse(location.search.slice(1)).e;

	useEffect(() => {
		const payload = {
			nonce: nonce,
			email: email,
		};

		const resetPasswordCheck = async () => {
			try {
				await doResetPassword(payload);
			} catch (err) {
				if (err && err.response && err.response.data) {
					const error = err.response.data;
					message.error(error.message, 6);
					this.props.history.push('/login');
				}
			}
		};

		resetPasswordCheck();
	}, []);

	const handleFinish = (data) => {
		const payload = {
			...data,
			nonce: nonce,
			email: email,
		};

		const resetPassword = async () => {
			try {
				const response = await doResetPassword(payload);
				return Promise.resolve(response);
			} catch (err) {
				return Promise.reject(err);
			}
		};

		return resetPassword();
	};

	return (
		<Content>
			<Helmet>
				<title>Foreward Network | User | Reset Password</title>
			</Helmet>
			<Container centralised>
				<View>
					<Section className="my-5 container-body container-body-md">
						<Title size="lg">Reset Password</Title>
						<ResetPasswordForm {...props} onSubmit={handleFinish} />
					</Section>
				</View>
			</Container>
		</Content>
	);
};

export default connect(null, { doResetPassword })(ResetPassword);
